/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert, Snackbar, Box, Button, Typography, CircularProgress, Backdrop,
  TextField, FormControl, InputLabel, Select, MenuItem, Paper, Chip, Grid,
  OutlinedInput, Checkbox, ListItemText, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import apiCall from '../../api';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';

function PushNotifications() {
  const { t } = useTranslation();
  const { user, userType } = useUser();
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    types: [],
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  // New state for confirmation dialog
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const userTypes = ['worker', 'driver', 'company', 'client'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotification({
      ...notification,
      [name]: value,
    });
  };

  const handleTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    setNotification({
      ...notification,
      types: typeof value === 'string' ? value.split(',') : value,
    });
  };

  // Handle open confirmation dialog
  const handleOpenConfirmDialog = () => {
    // Validation before showing confirmation dialog
    if (!notification.title.trim()) {
      setError(t('push_notifications_title_required'));
      return;
    }

    if (!notification.body.trim()) {
      setError(t('push_notifications_body_required'));
      return;
    }

    if (notification.types.length === 0) {
      setError(t('push_notifications_types_required'));
      return;
    }

    // Only super admin can send notifications
    if (userType === usersTypes.SUPER) {
      setConfirmDialogOpen(true);
    } else {
      setError(t('push_notifications_no_permission'));
    }
  };

  // Handle close confirmation dialog
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleSendNotification = () => {
    // Close dialog first
    setConfirmDialogOpen(false);

    // Proceed with sending notification
    setLoading(true);

    const notificationData = {
      types: notification.types,
      title: notification.title,
      body: notification.body,
      idToken: user.accessToken,
    };

    apiCall('/send-group-notifications', notificationData)
      .then((response) => {
        setSuccessMessage(t('push_notifications_sent_success'));

        // Reset form
        setNotification({
          title: '',
          body: '',
          types: [],
        });
      })
      .catch((err) => {
        setError(t('push_notifications_sent_fail'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleCloseSuccess = () => {
    setSuccessMessage('');
  };

  // Helper function to get formatted recipient list for confirmation dialog
  const getFormattedRecipients = () => notification.types
    .map((type) => t(`push_notifications_type_${type}`))
    .join(', ');

  return (
    <div>
      <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 3 }}>
        {t('profile_push_notifications_title')}
      </Typography>

      {error && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {successMessage && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      )}

      {loading && (
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {t('push_notifications_confirm_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t('push_notifications_confirm_message')}
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {t('push_notifications_confirm_details')}
            </Typography>
            <Typography variant="body2">
              <strong>
                {t('push_notifications_title_label')}
                :
              </strong>
              {' '}
              {notification.title}
            </Typography>
            <Typography variant="body2">
              <strong>
                {t('push_notifications_recipients_label')}
                :
              </strong>
              {' '}
              {getFormattedRecipients()}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>
                {t('push_notifications_body_label')}
                :
              </strong>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 0.5, px: 1, py: 1, bgcolor: 'rgba(0,0,0,0.05)', borderRadius: 1,
              }}
            >
              {notification.body}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>
            {t('push_notifications_confirm_cancel')}
          </Button>
          <Button onClick={handleSendNotification} variant="contained" color="primary" autoFocus>
            {t('push_notifications_confirm_send')}
          </Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
          {t('push_notifications_new')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="title"
              label={t('push_notifications_title_label')}
              value={notification.title}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'white' },
                },
                '& .MuiInputLabel-root': { color: 'white' },
                '& .MuiOutlinedInput-input': { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'white' },
                },
                '& .MuiInputLabel-root': { color: 'white' },
                '& .MuiOutlinedInput-input': { color: 'white' },
                '& .MuiSelect-icon': { color: 'white' },
              }}
            >
              <InputLabel id="types-label">{t('push_notifications_types_label')}</InputLabel>
              <Select
                labelId="types-label"
                multiple
                name="types"
                value={notification.types}
                onChange={handleTypeChange}
                input={<OutlinedInput label={t('push_notifications_types_label')} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={t(`push_notifications_type_${value}`)}
                        sx={{
                          color: 'white',
                          bgcolor: 'rgba(255, 255, 255, 0.1)',
                          '& .MuiChip-label': { color: 'white' },
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {userTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={notification.types.indexOf(type) > -1} />
                    <ListItemText primary={t(`push_notifications_type_${type}`)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              name="body"
              label={t('push_notifications_body_label')}
              value={notification.body}
              onChange={handleInputChange}
              variant="outlined"
              multiline
              rows={4}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'white' },
                },
                '& .MuiInputLabel-root': { color: 'white' },
                '& .MuiOutlinedInput-input': { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={handleOpenConfirmDialog}
                disabled={loading || userType !== usersTypes.SUPER}
              >
                {t('push_notifications_send')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default PushNotifications;
