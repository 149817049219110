/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert, Snackbar, Box, Button, Typography, CircularProgress, Backdrop,
  TextField, Paper, Grid, IconButton, List, ListItem, ListItemText, Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import apiCall from '../../api';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';

function InfoCenter() {
  const { t } = useTranslation();
  const { user, userType } = useUser();
  const [infoItems, setInfoItems] = useState([]);
  const [newInfoText, setNewInfoText] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Define fetchInfoNotifications function first before using it
  const fetchInfoNotifications = () => {
    if (!user || !user.accessToken) return;

    setLoading(true);

    apiCall('/get-app-info-notifications', { idToken: user.accessToken })
      .then((response) => {
        // Extract the notifications array from the response
        const notificationsArray = response && response.notifications ? response.notifications : [];
        setInfoItems(notificationsArray);
      })
      .catch((err) => {
        setError(t('info_center_fetch_error'));
        setInfoItems([]); // Reset to empty array on error
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Fetch existing info notifications
  useEffect(() => {
    // Only fetch if user is available and logged in
    if (user && user.accessToken) {
      fetchInfoNotifications();
    }
  }, [user]);

  const handleAddInfo = () => {
    if (!user || !user.accessToken) {
      setError(t('info_center_login_required'));
      return;
    }

    if (!newInfoText.trim()) {
      setError(t('info_center_text_required'));
      return;
    }

    // Only super admin can add notifications
    if (userType === usersTypes.SUPER) {
      setLoading(true);

      const infoData = {
        text: newInfoText,
        idToken: user.accessToken,
      };

      apiCall('/add-app-info-notifications', infoData)
        .then((response) => {
          setSuccessMessage(t('info_center_add_success'));

          // Refresh the list
          fetchInfoNotifications();

          // Clear the form
          setNewInfoText('');
        })
        .catch((err) => {
          setError(t('info_center_add_error'));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(t('info_center_no_permission'));
    }
  };

  const handleDeleteInfo = (id) => {
    if (!user || !user.accessToken) {
      setError(t('info_center_login_required'));
      return;
    }

    // Only super admin can delete notifications
    if (userType === usersTypes.SUPER) {
      setLoading(true);

      const deleteData = {
        id,
        idToken: user.accessToken,
      };

      apiCall('/remove-app-info-notifications', deleteData)
        .then((response) => {
          setSuccessMessage(t('info_center_delete_success'));

          // Refresh the list
          fetchInfoNotifications();
        })
        .catch((err) => {
          setError(t('info_center_delete_error'));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(t('info_center_no_permission'));
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleCloseSuccess = () => {
    setSuccessMessage('');
  };

  // Show loading indicator if user isn't loaded yet
  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  // Ensure infoItems is always an array
  const safeInfoItems = Array.isArray(infoItems) ? infoItems : [];

  return (
    <div>
      <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 3 }}>
        {t('profile_info_center_title')}
      </Typography>

      {error && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {successMessage && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      )}

      {loading && (
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* Add new info notification */}
      <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
        <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
          {t('info_center_add_new_title')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="infoText"
              label={t('info_center_text_label')}
              value={newInfoText}
              onChange={(e) => setNewInfoText(e.target.value)}
              variant="outlined"
              multiline
              rows={3}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'white' },
                },
                '& .MuiInputLabel-root': { color: 'white' },
                '& .MuiOutlinedInput-input': { color: 'white' },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddInfo}
                disabled={loading || userType !== usersTypes.SUPER}
              >
                {t('info_center_add_button')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* List of info notifications */}
      <Paper elevation={3} sx={{ p: 3, backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
        <Typography variant="h6" gutterBottom sx={{ color: 'white', mb: 2 }}>
          {t('info_center_list_title')}
        </Typography>

        {safeInfoItems.length === 0 ? (
          <Typography sx={{ color: 'white', textAlign: 'center', py: 2 }}>
            {t('info_center_no_items')}
          </Typography>
        ) : (
          <List sx={{ width: '100%' }}>
            {safeInfoItems.map((item, index) => (
              <React.Fragment key={item.id || index}>
                <ListItem
                  alignItems="flex-start"
                  secondaryAction={
                    userType === usersTypes.SUPER && (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteInfo(item.id)}
                        sx={{ color: 'white' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                  sx={{ py: 2 }}
                >
                  <ListItemText
                    primary={(
                      <Typography
                        component="div"
                        variant="body1"
                        sx={{
                          color: 'white',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item.text}
                      </Typography>
                    )}
                    secondary={(
                      <Typography
                        component="span"
                        variant="caption"
                        sx={{ color: 'rgba(255, 255, 255, 0.7)', display: 'block', mt: 1 }}
                      >
                        {item.created ? new Date(Number(item.created)).toLocaleString() : ''}
                      </Typography>
                    )}
                  />
                </ListItem>
                {index < safeInfoItems.length - 1 && (
                  <Divider variant="fullWidth" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
                )}
              </React.Fragment>
            ))}
          </List>
        )}
      </Paper>
    </div>
  );
}

export default InfoCenter;
